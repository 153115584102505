import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserNanoID } from '../users/usersTypes';

import { ProjectID, ProjectNanoID } from './projectsTypes';

export class ProjectCache {
  static indexCacheKey() {
    return 'projects';
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `projects-filtered-${searchFilterNanoId}`;
  }

  static burningCacheKey() {
    return 'projects-burning';
  }

  static favoriteCacheKey() {
    return 'projects-favorite';
  }

  static myProjectsCacheKey() {
    return 'my-projects';
  }

  static userProjectsCacheKey(userNanoId: UserNanoID) {
    return `projects-user-${userNanoId}`;
  }

  static showCacheKey() {
    return 'project';
  }

  static showRedirectCacheKey(projectId: ProjectID) {
    return `project-${projectId}-redirect`;
  }

  static selectFieldCacheKey() {
    return 'select-field-projects';
  }

  static tasksCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks`;
  }

  static messagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-messages`;
  }

  static pinnedMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-pinned-messages`;
  }

  static scopeOfWorkCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-scope-of-work`;
  }

  static forwardMessagesCacheKey() {
    return `projects-forward-messages`;
  }

  static projectMembersCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-members`;
  }

  static projectNotificationsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-notifications`;
  }

  static transactionsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-transactions`;
  }

  static attachmentsTasksCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-attachments-tasks`;
  }

  static filesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-files`;
  }

  static fileAttachmentsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-file-attachments`;
  }

  static imagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-images`;
  }

  static tasksResultsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks-results`;
  }

  static selectedProductsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-products`;
  }

  static selectedLifestylesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-lifestyles`;
  }

  static selectedMaterialsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-materials`;
  }

  static sidebarFilesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-files`;
  }

  static sidebarMediaCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-media`;
  }

  static billsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-bills`;
  }

  static showQueryKey(projectNanoId: ProjectNanoID) {
    return ['project', projectNanoId];
  }
}
