import React, { Fragment } from 'react';
import cl from 'classnames';

import { Translate } from '../../../../Translate';

import {
  DropzoneHelper,
  DropzoneHelperFileIds,
  DropzoneHelperFiles,
  DropzoneHelperRequiredProps
} from '../../../../dropzone/DropzoneHelper';

import { DropzoneFieldControlRequiredProps } from './DropzoneFieldControl.types';

interface DropzoneFieldControlProps {
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

function DropzoneFieldControl({
  type,
  value,
  disabled,
  error,
  errorClassName,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  withoutTabs,
  maxFiles,
  preventMaxFilesOverload,
  initialFiles,
  initialFileIds,
  onChange
}: DropzoneFieldControlProps &
  DropzoneFieldControlRequiredProps &
  DropzoneHelperRequiredProps) {
  return (
    <Fragment>
      {i18nLabel || label ? (
        <label
          htmlFor={labelFor}
          className={cl(
            labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
          )}
        >
          {i18nLabel ? <Translate id={i18nLabel} /> : label}
        </label>
      ) : null}
      <div className="mt-1">
        <DropzoneHelper
          type={type}
          value={value}
          disabled={disabled}
          withoutTabs={withoutTabs}
          maxFiles={maxFiles}
          preventMaxFilesOverload={preventMaxFilesOverload}
          onChange={onChange}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
        />
      </div>
      {error && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </Fragment>
  );
}

export default DropzoneFieldControl;
