import React, { ForwardedRef, useCallback, useState } from 'react';
import mergeRefs from 'react-merge-refs';

import { I18nText } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../tooltips/TooltipSingletonTarget';
import { Translate } from '../../Translate';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type PureTooltipIconRefButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureTooltipIconRefButtonHelperDefaultProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: PureTooltipIconRefButtonHelperOnClick;
  onMouseEnter?: () => void;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

export interface PureTooltipIconRefButtonHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureTooltipIconRefButtonHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureTooltipIconRefButtonHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

interface PureTooltipIconRefButtonHelperWithTooltipI18nTextProps {
  tooltipI18nText: I18nText;
  tooltipText?: never;
}

interface PureTooltipIconRefButtonHelperWithTooltipTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
}

type PureTooltipIconRefButtonHelperProps =
  PureTooltipIconRefButtonHelperDefaultProps &
    (
      | PureTooltipIconRefButtonHelperIconProps
      | PureTooltipIconRefButtonHelperWithI18nProps
      | PureTooltipIconRefButtonHelperWithTextProps
    ) &
    (
      | PureTooltipIconRefButtonHelperWithTooltipI18nTextProps
      | PureTooltipIconRefButtonHelperWithTooltipTextProps
    );

const PureTooltipIconRefButtonHelper = React.forwardRef<
  HTMLButtonElement,
  PureTooltipIconRefButtonHelperProps
>(
  (
    componentProps: PureTooltipIconRefButtonHelperProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      id,
      className,
      style,
      disabled,
      icon = null,
      iconClassName,
      tooltipI18nText,
      tooltipText,
      tooltipPlacement,
      tooltipSingleton,
      onClick,
      onMouseEnter,
      i18nTextClassName,
      i18nText,
      text
    } = componentProps;
    const [referenceTooltipElement, setReferenceTooltipElement] =
      useState<HTMLButtonElement | null>(null);

    const handleClick = useCallback<PureTooltipIconRefButtonHelperOnClick>(
      (e) => {
        e.preventDefault();
        onClick?.(e);
      },
      [onClick]
    );

    const tooltipView = tooltipI18nText ? (
      <Tooltip
        withArrow
        referenceElement={referenceTooltipElement}
        tooltipI18nText={tooltipI18nText}
        placement={tooltipPlacement}
      />
    ) : (
      <Tooltip
        withArrow
        referenceElement={referenceTooltipElement}
        tooltipText={tooltipText as string}
        placement={tooltipPlacement}
      />
    );

    const tooltipSingletonView = tooltipI18nText ? (
      <TooltipSingletonTarget
        referenceElement={referenceTooltipElement}
        tooltipI18nText={tooltipI18nText}
      />
    ) : (
      <TooltipSingletonTarget
        referenceElement={referenceTooltipElement}
        tooltipText={tooltipText as string}
      />
    );

    return (
      <button
        id={id}
        className={className}
        style={style}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        ref={mergeRefs([ref, setReferenceTooltipElement])}
        type="button"
      >
        {icon ? (
          <Icon
            className={iconClassName}
            icon={icon}
            id={id ? `${id}-icon` : undefined}
          />
        ) : null}
        {icon && i18nText ? ' ' : null}
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
        {tooltipSingleton ? tooltipSingletonView : tooltipView}
      </button>
    );
  }
);

PureTooltipIconRefButtonHelper.displayName = 'PureTooltipIconRefButtonHelper';

export default PureTooltipIconRefButtonHelper;
