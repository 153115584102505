import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../dropzone/DropzoneHelper';

import {
  DropzoneFieldControl,
  DropzoneFieldControlRequiredProps
} from './components/DropzoneFieldControl';

import { DropzoneFieldRequiredProps } from './DropzoneField.types';

interface DropzoneFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
}

function DropzoneField<T>({
  control,
  disabled,
  name,
  error,
  type,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  withoutTabs,
  maxFiles,
  preventMaxFilesOverload,
  initialFiles,
  initialFileIds
}: DropzoneFieldProps<T> &
  DropzoneFieldRequiredProps<T> &
  DropzoneFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DropzoneFieldControl
          type={type}
          value={value as string[]}
          disabled={disabled}
          error={error}
          labelFor={labelFor}
          i18nLabel={i18nLabel}
          label={label}
          labelClassName={labelClassName}
          withoutTabs={withoutTabs}
          maxFiles={maxFiles}
          preventMaxFilesOverload={preventMaxFilesOverload}
          onChange={onChange}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
        />
      )}
    />
  );
}

export default DropzoneField;
