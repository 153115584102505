import React, { Suspense, useState, useEffect } from 'react';

import { ClassName } from '../../../../types';

const Markdown = React.lazy(() => import('react-markdown'));

const components = {
  a: ({ node, ...props }) => <a className="underline" {...props} />
};

interface ReactMarkdownProps {
  className?: ClassName;
  children: string;
  allowedElements?: string[];
  unwrapDisallowed?: boolean;
  linkTarget?: '_blank';
}

function ReactMarkdown({
  className,
  children,
  allowedElements,
  unwrapDisallowed,
  linkTarget
}: ReactMarkdownProps) {
  const [rehypePlugins, setRehypePlugins] = useState<any[] | null>(null);
  const [remarkPlugins, setRemarkPlugins] = useState<any[] | null>(null);

  useEffect(() => {
    Promise.all([
      import('remark-breaks').then((imported) => imported.default),
      import('remark-gfm').then((imported) => imported.default)
    ]).then((imported) => setRemarkPlugins(imported));
    import('rehype-raw').then((imported) =>
      setRehypePlugins([imported.default])
    );
  }, [setRemarkPlugins, setRehypePlugins]);

  if (!rehypePlugins || !remarkPlugins) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Markdown
        className={className}
        rehypePlugins={rehypePlugins}
        remarkPlugins={remarkPlugins}
        linkTarget={linkTarget}
        allowedElements={allowedElements}
        unwrapDisallowed={unwrapDisallowed}
        components={components}
      >
        {children}
      </Markdown>
    </Suspense>
  );
}

export default ReactMarkdown;
