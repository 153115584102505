import React from 'react';

import { DropzoneTab, DropzoneTabItem, DropzoneTabFiles } from '../DropzoneTab';

import { words } from '../../../locales/keys';

import { DropzoneTabType, DropzoneTabOnChange } from './DropzoneTabs.types';

const tabs: DropzoneTabItem[] = [
  { name: 'general', i18nText: words.general },
  { name: 'drawing', i18nText: words.drawings },
  { name: 'reference', i18nText: words.references },
  { name: 'texture', i18nText: words.textures },
  { name: '3ds', i18nText: words.threeDs }
];

interface DropzoneTabsProps {
  allFiles: DropzoneTabFiles;
  activeTab: DropzoneTabType;
  onChange: DropzoneTabOnChange;
}

function DropzoneTabs({ allFiles, activeTab, onChange }: DropzoneTabsProps) {
  return (
    <div>
      <div className="sm:block">
        <nav className="flex space-x-2">
          {tabs.map((tab) => (
            <DropzoneTab
              key={`dropzone-field-list-tab-${tab.name}`}
              active={activeTab === tab.name}
              allFiles={allFiles}
              item={tab}
              onChange={onChange}
            />
          ))}
        </nav>
      </div>
    </div>
  );
}

export default DropzoneTabs;
