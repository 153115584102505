import {
  FetchTagsFilters,
  FetchTagsSort,
  FetchTagsPage,
  FetchTagsLimit,
  TagType
} from '../../tagsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_TAGS_FILTERS,
  INITIAL_TAGS_LIMIT,
  INITIAL_TAGS_PAGE,
  INITIAL_TAGS_SORT
} from '../../tagsConstants';

interface TagsOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchTagsFilters;
  initialSort?: FetchTagsSort;
  initialPage?: FetchTagsPage;
  initialLimit?: FetchTagsLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'tags';

function usePaginatedTags<ItemType = TagType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TAGS_FILTERS,
  initialSort = INITIAL_TAGS_SORT,
  initialPage = INITIAL_TAGS_PAGE,
  initialLimit = INITIAL_TAGS_LIMIT,
  options = {}
}: TagsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    tagsData: data,
    tags: items,
    tagsError: itemsError,
    tagsTotalCount: itemsTotalCount,
    tagsFetched: isFetched,
    tagsLoading: isLoading,
    tagsIsPlaceholderData: isPlaceholderData,
    tagsFilters: currentFilters,
    tagsSort: currentSort,
    tagsPage: currentPage,
    tagsLimit: currentLimit,
    filterTags: filterItems,
    clearTagsFilters: clearItemsFilters,
    sortTags: sortItems,
    paginateTags: paginateItems,
    limitTags: limitItems,
    prefetchTags: prefetchItems
  };
}

export default usePaginatedTags;
