import React, { ReactNode } from 'react';

import { IconsEnum } from '../../../assets/icons/types';
import { I18nText, PositionStyleEnum } from '../../../types';

import { Popover, usePopoverToggle } from '../../Popover';
import { PureTooltipIconRefButtonHelper } from '../../buttons/PureTooltipIconRefButtonHelper';

import { PopoverPlacement } from '../../Popover/popoverConstants';
import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

const dropdownMenuOffset = 12;

interface TooltipDropdownHelperWithTooltipI18nTextProps {
  tooltipI18nText: I18nText;
  tooltipText?: never;
  tooltipSingleton?: boolean;
}

interface TooltipDropdownHelperWithTooltipTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
  tooltipSingleton?: boolean;
}

interface TooltipDropdownHelperDefaultProps {
  buttonClassName?: string;
  children: ReactNode;
  className?: string;
  dropdownOffset?: number;
  dropdownPlacement?: PopoverPlacement;
  icon?: IconsEnum;
  iconClassName?: string;
  popoverClassName?: string;
  popoverPositionStyle?: PositionStyleEnum;
  tooltipPlacement?: TooltipPlacement;
  closeOnOuterClick?: boolean;
}

type TooltipDropdownHelperProps = TooltipDropdownHelperDefaultProps &
  (
    | TooltipDropdownHelperWithTooltipI18nTextProps
    | TooltipDropdownHelperWithTooltipTextProps
  );

function TooltipDropdownHelper({
  buttonClassName,
  children,
  className,
  dropdownOffset = dropdownMenuOffset,
  dropdownPlacement = PopoverPlacement.TOP,
  icon = IconsEnum.DOTS_HORIZONTAL_OUTLINE,
  iconClassName,
  popoverClassName,
  popoverPositionStyle,
  tooltipI18nText,
  tooltipPlacement = TooltipPlacement.TOP,
  tooltipText,
  tooltipSingleton,
  closeOnOuterClick
}: TooltipDropdownHelperProps) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverToggle();

  return (
    <div className={className || 'relative inline-flex'}>
      {tooltipI18nText ? (
        <PureTooltipIconRefButtonHelper
          className={
            buttonClassName ||
            'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
          }
          icon={icon}
          iconClassName={iconClassName}
          onClick={togglePopover}
          ref={referencePopoverCallbackRef}
          tooltipI18nText={tooltipI18nText}
          tooltipPlacement={tooltipPlacement}
          tooltipSingleton={tooltipSingleton}
        />
      ) : (
        <PureTooltipIconRefButtonHelper
          className={
            buttonClassName ||
            'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
          }
          icon={icon}
          onClick={togglePopover}
          ref={referencePopoverCallbackRef}
          tooltipPlacement={tooltipPlacement}
          tooltipText={tooltipText}
          tooltipSingleton={tooltipSingleton}
        />
      )}

      {isPopoverOpen ? (
        <Popover
          className={
            popoverClassName ||
            'min-w-48 z-30 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700'
          }
          closePopover={closePopover}
          distanceOffset={dropdownOffset}
          placement={dropdownPlacement}
          positionStyle={popoverPositionStyle}
          referenceElement={referencePopoverElement}
          closeOnOuterClick={closeOnOuterClick}
        >
          {children}
        </Popover>
      ) : null}
    </div>
  );
}

export default TooltipDropdownHelper;
