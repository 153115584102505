import { gql } from 'graphql-request';

import { TagID, TagLocalizedName } from '../tagsTypes';

export interface FetchTagNamesQueryResponse {
  id: TagID;
  localizedName: TagLocalizedName;
}

export const FETCH_TAG_NAMES_QUERY = gql`
  query Tags(
    $filters: TagsFilters
    $sort: [TagsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tags(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      paginationInfo {
        nextPage
        totalCount
      }
      nodes {
        id
        localizedName
      }
    }
  }
`;
