import React, { Fragment, useCallback, useState } from 'react';

import {
  ClassName,
  I18nText,
  IsDisabled,
  ErrorMessage,
  IsFetched
} from '../../../../../types';

import { PayoneerCheckoutPaymentContainer } from '../../../../bills/components/containers/PayoneerCheckoutPaymentContainer';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { PayoneerCheckoutPayButtonBill } from './PayoneerCheckoutPayButton.types';
import { StaticImageHelper } from '../../../../../helpers/StaticImageHelper';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import payoneerLogo1 from '../../../../../assets/images/payoneer/combined_logo.png';
import payoneerLogo2 from '../../../../../assets/images/payoneer/combined_logo@2x.png';
import payoneerLogo3 from '../../../../../assets/images/payoneer/combined_logo@3x.png';

const payoneerLogoSrcVersions = {
  '3x': payoneerLogo3,
  '2x': payoneerLogo2,
  '1x': payoneerLogo1
};

interface PayoneerCheckoutPayButtonProps {
  bill?: PayoneerCheckoutPayButtonBill;
  showClassName?: ClassName;
  hideClassName?: ClassName;
  disabled?: IsDisabled;
  showI18nText?: I18nText;
  hideI18nText?: I18nText;
  onMouseEnter: () => void;
  billError?: ErrorMessage;
  billFetched?: IsFetched;
}

function PayoneerCheckoutPayButton({
  bill,
  showClassName,
  hideClassName,
  disabled,
  showI18nText,
  hideI18nText,
  onMouseEnter,
  billError,
  billFetched
}: PayoneerCheckoutPayButtonProps) {
  const [widgetId] = useState<string>(generateNanoId());

  const [showForm, setShowForm] = useState<boolean>(false);

  const handleToggleShowForm = useCallback<() => void>(() => {
    setShowForm((prev) => !prev);
  }, []);

  const handleHideForm = useCallback<() => void>(() => {
    setShowForm(false);
  }, []);

  return (
    <Fragment>
      <div className="space-y-2">
        <div className="flex items-center justify-between m-auto max-w-full px-2 space-x-4 w-72">
          <div className="text-sm">Visa / Mastercard</div>
          <div>
            <StaticImageHelper
              className="h-8"
              src={payoneerLogo1}
              srcSetData={payoneerLogoSrcVersions}
              alt="Visa / Mastercard"
            />
          </div>
        </div>
        <div className="space-y-2">
          {showForm ? (
            <div className="px-4 pt-4 pb-1">
              <LoadingSkeleton loaded={billFetched}>
                {bill ? (
                  <PayoneerCheckoutPaymentContainer
                    bill={bill}
                    widgetId={widgetId}
                    beforeOtherWidgetRun={handleHideForm}
                  />
                ) : null}
              </LoadingSkeleton>
              <AlertMessage addClassName="mt-2" message={billError} />
            </div>
          ) : null}
        </div>
        <div className="flex justify-center space-x-1">
          <PureButtonHelper
            className={showForm ? hideClassName : showClassName}
            i18nText={showForm ? hideI18nText : showI18nText}
            disabled={disabled}
            onClick={handleToggleShowForm}
            onMouseEnter={onMouseEnter}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default PayoneerCheckoutPayButton;
