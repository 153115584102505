import React, { Fragment, ReactNode } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { ModalOptions, useModal } from '../../modals/hooks/useModal';

import { FormModal } from '../../modals/FormModal';

import { PureTooltipIconButtonHelper } from '../PureTooltipIconButtonHelper';
import { PureIconButtonHelper } from '../PureIconButtonHelper';
import { ButtonHelper } from '../ButtonHelper';

import { FormModalButtonDefaultProps } from './FormModalButton.types';
import { PureButtonHelper } from '../PureButtonHelper';

interface FormModalButtonProps {
  children: ReactNode;
  form: string;
  className?: string;
  buttonChildren?: ReactNode;
  i18nText?: I18nText;
  i18nTextClassName?: I18nText;
  icon?: IconsEnum;
  iconClassName?: string;
  tooltipSingleton?: boolean;
}

function FormModalButton({
  afterSubmit,
  buttonChildren,
  children,
  className,
  disabled,
  form,
  i18nSubmitText,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  id,
  isLoading,
  tooltipI18nText,
  tooltipPlacement,
  onClose,
  onOpen,
  onSubmit,
  onToggle,
  tooltipSingleton,
  submitDisabled
}: FormModalButtonProps & FormModalButtonDefaultProps & ModalOptions) {
  const { isOpen, showModal, hideModal, handleSubmit } = useModal({
    onOpen,
    onClose,
    onToggle,
    onSubmit,
    afterSubmit
  });

  const pureI18nTextButton = i18nText ? (
    <PureButtonHelper
      className={className}
      disabled={disabled}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
    />
  ) : null;

  const pureIconButton = icon ? (
    <PureIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
    />
  ) : null;

  const tooltipI18nTextButton = tooltipI18nText ? (
    <PureTooltipIconButtonHelper
      className={className}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
      tooltipSingleton={tooltipSingleton}
    />
  ) : null;

  const button = buttonChildren ? (
    <ButtonHelper className={className} disabled={disabled} onClick={showModal}>
      {buttonChildren}
    </ButtonHelper>
  ) : null;

  return (
    <Fragment>
      {button || tooltipI18nTextButton || pureIconButton || pureI18nTextButton}
      <FormModal
        submitDisabled={submitDisabled}
        form={form}
        handleSubmit={handleSubmit}
        hideModal={hideModal}
        i18nSubmitText={i18nSubmitText}
        i18nTitle={i18nTitle}
        id={id}
        isLoading={isLoading}
        isOpen={isOpen}
      >
        {children}
      </FormModal>
    </Fragment>
  );
}
export default FormModalButton;
