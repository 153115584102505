import { useEffect, useMemo } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import { I18nText, IsLoading } from '../../../../types';
import { FetchTagsSortTypes, TagID } from '../../tagsTypes';

import { usePaginatedTags } from '../../hooks/usePaginatedTags';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { MultiSelectDataType } from '../../../../helpers/MultiSelect/types';
import { MultiSelectField } from '../../../../helpers/FormFields/MultiSelectField';
import {
  MultiSelectFieldProps,
  MultiSelectFieldControlProps
} from '../../../../helpers/FormFields/MultiSelectField';

import { TagCache } from '../../TagCache';
import {
  FetchTagNamesQueryResponse,
  FETCH_TAG_NAMES_QUERY
} from '../../queries/fetchTagNames.query';

export type TagsMultiSelectDataType = MultiSelectDataType[];

interface TagsMultiSelectFieldProps {
  i18nPlaceholder?: I18nText;
  initialTagIds?: TagID[];
  isLoading: IsLoading;
  multi: boolean;
  setValue?: (name: string, value: TagsMultiSelectDataType) => void;
}

function TagsMultiSelectField<FormDataType>({
  control,
  i18nPlaceholder,
  initialTagIds,
  inputWrapperClassName,
  isClearable,
  isLoading,
  multi,
  name,
  setValue
}: TagsMultiSelectFieldProps &
  MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType>) {
  const { tags, tagsLoading } = usePaginatedTags<FetchTagNamesQueryResponse>({
    cacheKey: TagCache.indexCacheKey(),
    query: FETCH_TAG_NAMES_QUERY,
    initialSort: [FetchTagsSortTypes.NAME_ASC]
  });

  const tagsData = useMemo<TagsMultiSelectDataType>(() => {
    return tags.map((tag) => ({ value: tag.id, label: tag.localizedName }));
  }, [tags]);

  const prevCheckedIds = usePreviousValue(initialTagIds);

  useEffect(() => {
    if (setValue && !isEqual(sortBy(prevCheckedIds), sortBy(initialTagIds))) {
      setValue(
        name,
        filter(tagsData, (tag) => includes(initialTagIds, tag.value))
      );
    }
  }, [setValue, tagsData, prevCheckedIds, initialTagIds, name]);

  return (
    <MultiSelectField<FormDataType>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      classNamePrefix="av"
      closeMenuOnSelect={false}
      control={control}
      data={tagsData}
      disabled={isLoading}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isClearable={isClearable}
      isSearchable
      labelClassName="font-light uppercase"
      menuPosition="fixed"
      multi={multi}
      name={name}
      optionsLoading={tagsLoading}
    />
  );
}

export default TagsMultiSelectField;
