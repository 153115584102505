import { ID, Item, UUID } from '../../types';

export type TagID = ID;
export type TagUUID = UUID;
export type TagName = string;
export type TagLocalizedName = string;

export interface FetchTagsFilters {
  name?: {
    ilike?: string;
  };
  createdAt?: string;
}

export enum FetchTagsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchTagsSort = string[];

export type FetchTagsPage = number;

export type FetchTagsLimit = number;

interface TagUserType {
  id: ID;
  uuid: UUID;
  fullName: string;
  role: string;
  image: {
    uuid: UUID;
    file: string;
  };
}

export interface TagType extends Item {
  createdAt: string;
  deletedAt: string;
  id: ID;
  localizedName?: string;
  name: string;
  nameEn?: string;
  updatedAt: string;
  user: TagUserType;
  userId: ID;
  uuid: UUID;
  visibleForClients: boolean;
}
