import { ProjectUUID } from '../projects/projectsTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TaskID, TaskNanoID } from './tasksTypes';
import { UserNanoID } from '../users/usersTypes';

export class TaskCache {
  static indexCacheKey() {
    return 'tasks';
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-filtered-${searchFilterNanoId}`;
  }

  static burningCacheKey() {
    return 'tasks-burning';
  }

  static favoriteCacheKey() {
    return 'tasks-favorite';
  }

  static myTasksCacheKey() {
    return 'my-tasks';
  }

  static userTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}`;
  }

  static itemsResultsCacheKey() {
    return 'tasks-results';
  }

  static showCacheKey() {
    return 'task';
  }

  static showRedirectCacheKey(taskId: TaskID) {
    return `project-${taskId}-redirect`;
  }

  static showBreadcrumbsCacheKey() {
    return 'task-breadcrumbs';
  }

  static userResultsCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-results`;
  }

  static userUnderpaymentCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-underpayment`;
  }

  static userDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-debt`;
  }

  static userBadDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt`;
  }

  static userBadDebtAmountCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt-amount`;
  }

  static selectByProjectFieldCacheKey(projectUuid: ProjectUUID) {
    return `select-by-project-${projectUuid}-field-tasks`;
  }

  static messagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-messages`;
  }

  static pinnedMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-pinned-messages`;
  }

  static scopeOfWorkCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-scope-of-work`;
  }

  static forwardMessageCacheKey(projectUuid: ProjectUUID) {
    return `tasks-${projectUuid}-forward-messages`;
  }

  static taskMembersCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-members`;
  }

  static taskNotificationsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-notifications`;
  }

  static sidebarFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-files`;
  }

  static sidebarMediaCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-media`;
  }

  static filesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-files`;
  }

  static fileAttachmentsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-file-attachments`;
  }

  static imagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-images`;
  }

  static resultsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results`;
  }

  static sourceFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-source-files`;
  }

  static selectedProductsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-products`;
  }

  static selectedLifestylesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-lifestyles`;
  }

  static selectedMaterialsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-materials`;
  }

  static transactionsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-transactions`;
  }

  static billsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-bills`;
  }

  static showQueryKey(taskNanoId: TaskNanoID) {
    return ['task', taskNanoId];
  }
}
