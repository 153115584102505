import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import without from 'lodash/without';
import size from 'lodash/size';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';

import {
  DropzoneTabs,
  DropzoneTabType,
  DropzoneTabOnChange
} from '../../dropzone/DropzoneTabs';

import {
  S3MultipartDropzoneArea,
  S3MultipartDropzoneAreaOnFilesAccepted,
  S3MultipartDropzoneAreaOnFileCreated,
  S3MultipartDropzoneAreaOnUploadProgress,
  S3MultipartDropzoneAreaOnFileUploaded,
  S3MultipartDropzoneAreaOnFileFailed
} from '../../dropzone/S3MultipartDropzoneArea';

import { usePreviousValue } from '../../../common/hooks/usePreviousValue';

import { DropzoneUploadedFiles } from '../../dropzone/DropzoneUploadedFiles';

import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles,
  DropzoneHelperFile,
  DropzoneHelperRequiredProps,
  DropzoneHelperRemovedFileIds
} from './DropzoneHelper.types';

interface DropzoneHelperProps {
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  value: DropzoneHelperFileIds;
  disabled?: boolean;
  onChange: (value: DropzoneHelperFileIds) => void;
  onChangeFiles?: (files: DropzoneHelperFiles) => void;
}

const initialState = {};
const initialIds = [];

function DropzoneHelper({
  type,
  initialFiles = initialState,
  initialFileIds = initialIds,
  // value,
  disabled,
  maxFiles,
  preventMaxFilesOverload,
  onChange,
  onChangeFiles,
  withoutTabs
}: DropzoneHelperProps & DropzoneHelperRequiredProps) {
  const removedFileIdsRef = useRef<DropzoneHelperRemovedFileIds>();
  const [activeTab, setActiveTab] = useState<DropzoneTabType>('general');
  const [dataParams, setDataParams] = useState<{
    [key: string]: boolean | string;
  } | null>(null);
  const [allFiles, setAllFiles] = useState<DropzoneHelperFiles>(initialFiles);
  const [allFileIds, setAllFileIds] =
    useState<DropzoneHelperFileIds>(initialFileIds);
  const [removedFileIds, setRemovedFileIds] =
    useState<DropzoneHelperRemovedFileIds>(initialFileIds);
  const prevAllFileIds = usePreviousValue(allFileIds);

  removedFileIdsRef.current = removedFileIds;

  const handleTabChange = useCallback<DropzoneTabOnChange>(
    (tabName) => {
      setActiveTab(tabName);
      setDataParams({ [tabName]: true });
    },
    [setDataParams]
  );

  const handleFilesDropped =
    useCallback<S3MultipartDropzoneAreaOnFilesAccepted>(
      (files) =>
        setAllFiles((prevState) => ({
          ...prevState,
          ...fromPairs<DropzoneHelperFile>(
            files.map((file) => [
              file.id,
              {
                ...file,
                state: 'processing' as const,
                activeTab,
                progresses: {}
              }
            ])
          )
        })),
      [activeTab]
    );

  const handleFileCreated = useCallback<S3MultipartDropzoneAreaOnFileCreated>(
    (file, presignedUrls) =>
      setAllFiles((prevState) => ({
        ...prevState,
        [file.id]: {
          ...prevState[file.id],
          progresses: fromPairs(
            presignedUrls.map((presignedUrl, index) => [index, 0])
          )
        }
      })),
    []
  );

  const handleUploadProgress =
    useCallback<S3MultipartDropzoneAreaOnUploadProgress>(
      (id, partNumber, progress) => {
        if (size(removedFileIdsRef.current) > 0) {
          const isRemovedFileId = find(
            removedFileIdsRef.current,
            (removedFileId) => removedFileId === id
          );
          if (isRemovedFileId) {
            return;
          }
        }
        setAllFiles((prevState) => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            progresses: {
              ...(prevState[id].progresses || {}),
              [partNumber]: progress
            },
            activeTab
          }
        }));
      },
      [activeTab]
    );

  const handleFileUploaded = useCallback<S3MultipartDropzoneAreaOnFileUploaded>(
    (id, uploadedId) => {
      setAllFiles((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          progresses: fromPairs(
            keys(prevState[id].progresses || {}).map((p) => [p, 100])
          ),
          state: 'finished' as const,
          uploadedId,
          activeTab
        }
      }));
      setAllFileIds((prevState) => [...prevState, uploadedId]);
    },
    [activeTab]
  );

  const handleFileFailed = useCallback<S3MultipartDropzoneAreaOnFileFailed>(
    (id) =>
      setAllFiles((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          state: 'failed' as const,
          activeTab
        }
      })),
    [activeTab]
  );

  const handleRemoveItem = useCallback<(id: string) => void>(
    (id: string) => {
      setAllFiles((prevState) => omit<DropzoneHelperFiles>(prevState, id));
      setAllFileIds((prevState) =>
        without<string>(prevState, allFiles[id].uploadedId)
      );
      setRemovedFileIds((prevState) => [...prevState, id]);
    },
    [allFiles, setAllFiles]
  );

  useEffect(() => {
    if (size(removedFileIds) > 0) {
      removedFileIds.forEach((removedFileId) => {
        if (allFiles[removedFileId]) {
          setAllFiles((prevState) =>
            omit<DropzoneHelperFiles>(prevState, removedFileId)
          );
          setAllFileIds((prevState) =>
            without<string>(prevState, allFiles[removedFileId].uploadedId)
          );
          setRemovedFileIds((prevState) =>
            without<string>(prevState, removedFileId)
          );
        }
      });
    }
  }, [allFileIds, allFiles, removedFileIds]);

  useEffect(() => {
    if (!isEqual(prevAllFileIds, allFileIds)) {
      onChange(allFileIds);
    }
  }, [allFileIds, onChange, prevAllFileIds]);

  useEffect(() => {
    onChangeFiles?.(allFiles);
  }, [allFiles, onChangeFiles]);

  return (
    <Fragment>
      {withoutTabs ? null : (
        <DropzoneTabs
          allFiles={allFiles}
          activeTab={activeTab}
          onChange={handleTabChange}
        />
      )}
      <div className="mt-2">
        <S3MultipartDropzoneArea
          type={type}
          dataParams={dataParams}
          disabled={disabled}
          maxFiles={maxFiles}
          activeFilesCount={size(
            filter(
              allFiles,
              (file) =>
                file?.state === 'initialized' ||
                file?.state === 'processing' ||
                file?.state === 'finished'
            )
          )}
          preventMaxFilesOverload={preventMaxFilesOverload}
          onFilesAccepted={handleFilesDropped}
          onFileCreated={handleFileCreated}
          onUploadProgress={handleUploadProgress}
          onFileUploaded={handleFileUploaded}
          onFileFailed={handleFileFailed}
        />
        <DropzoneUploadedFiles
          activeTab={activeTab}
          allFiles={allFiles}
          onRemoveFile={handleRemoveItem}
        />
      </div>
    </Fragment>
  );
}

export default DropzoneHelper;
